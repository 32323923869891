import { FC, useEffect, useState } from 'react';
import type { Study } from '@/model/Study.model.ts';
import { Cage, CageApiId } from '@/model/Cage.model.ts';
import { _isNotEmpty } from '@/littledash.ts';
import { useApiHook } from '@/support/Hooks/api/useApiHook.ts';
import FoodIntakeView from '@/components/Workflow/Show/Views/Consumption/FoodIntakeView.tsx';
import { useChangeAnimalActions, useSelectedCage } from '@/utils/workflow/useWorkflow.tsx';
import { notAborted } from '@/support/Hooks/fetch/useAbortController.ts';
import { ConsumptionMetric, ConsumptionMetricStatus } from '@/model/Consumption.model.ts';
import ApiErrorBanner from '@/components/ApiErrorBanner';
import { getFirstAnimal, getNextCage } from '@/components/Workflow/Show/Show.utils.ts';
import { WorkflowSetup } from '@/components/Workflow/Show/Workflow.model.ts';
import { Animal } from '@/model/Animal.model.ts';

interface WorkflowConsumptionProps {
  study: Study;
  collections: Array<Cage>;
  subjects: Array<Animal>;
  workflowSetup: WorkflowSetup;
  focusSearch: () => void;
}

const WorkflowConsumption: FC<WorkflowConsumptionProps> = ({
  study,
  collections,
  subjects,
  workflowSetup,
  focusSearch,
}) => {
  const [apiError, setApiError] = useState<unknown | false>(false);
  const { selectedCage } = useSelectedCage();
  const { changeAnimal, changeCage } = useChangeAnimalActions();
  const [selectedCageApiId, setSelectedCageApiId] = useState<CageApiId>();
  const [cageConsumptionData, setCageConsumptionData] = useState<Array<ConsumptionMetric>>();
  const [cageMetricStatus, setCageMetricStatus] = useState<ConsumptionMetricStatus>('end');
  const { workflowTabConfig } = workflowSetup;

  useEffect(() => {
    if (_isNotEmpty(selectedCage)) {
      const cageId = collections.find((cage: Cage) => cage.id === selectedCage)?.api_id as CageApiId;
      setSelectedCageApiId(cageId);
    }
  }, [collections, selectedCage]);

  const { invoke: fetchConsumptionData } = useApiHook({
    endpoint: 'GET /api/v1/studies/{studyApiId}/cages/{cageApiId}/consumption',
    invokeOnInit: false,
    path: { studyApiId: study.api_id, cageApiId: selectedCageApiId as CageApiId },
    query: { type: 'food' },
    options: { onError: { throw: false, capture: true, toast: false, slug: 'food-consumption-fetch' } },
  });

  const onSave: Record<string, () => void> = {
    'focus-search': () => focusSearch(),
    'first-subject': () => {
      const firstAnimal = getFirstAnimal(selectedCage, subjects);
      changeAnimal({ type: 'SELECT_ANIMAL', animalId: firstAnimal.id });
    },
    'next-cage': () => {
      const nextCage = getNextCage(selectedCage, collections);
      changeCage({ type: 'SELECT_CAGE', cageId: nextCage.id });
    },
  };

  const initialise = async () => {
    try {
      const response = await fetchConsumptionData();
      if (response.type === 'success') {
        const consumptionData = response.body.data as Array<ConsumptionMetric>;
        setCageConsumptionData(consumptionData);
        if (consumptionData.length > 0) {
          setCageMetricStatus(consumptionData[0].status);
        } else {
          setCageMetricStatus('end');
        }
      }
    } catch (apiError) {
      if (notAborted(apiError)) {
        setApiError(apiError);
      }
    }
  };

  const handleSave = () => {
    onSave?.[workflowTabConfig?.[4]?.onSave]?.();
    initialise();
  };

  useEffect(() => {
    if (_isNotEmpty(selectedCageApiId)) {
      initialise();
    }
  }, [selectedCageApiId]);

  return (
    <div className="w-60 bg-light-gray flex flex-column" style={{ minHeight: '100%' }}>
      <div className="bb b--moon-gray ph4 pt3 pv3 bg-white">
        <span className="basier-med lh-title f6 near-black">Food Intake</span>
      </div>
      {apiError ? (
        <ApiErrorBanner
          className="mw6 mb4"
          error={apiError}
          title="There was an error fetching study data"
          text="An error has occurred when fetching study data, please try again. If this keeps occurring please contact support."
        />
      ) : null}
      <div className="tab-content ui__workflow__expand">
        {_isNotEmpty(selectedCageApiId) && (
          <FoodIntakeView
            studyApiId={study.api_id}
            selectedCageApiId={selectedCageApiId}
            cageConsumptionData={cageConsumptionData}
            cageMetricStatus={cageMetricStatus}
            handleSave={handleSave}
          ></FoodIntakeView>
        )}
      </div>
    </div>
  );
};

export default WorkflowConsumption;
