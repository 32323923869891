//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { Controller, ControllerRenderProps, useFormContext } from 'react-hook-form';
import FormField from '@/components/Modals/SetupWorkflow/Forms/FormField.tsx';
import Switch from '@/components/UI/FormElements/Switch';
import { WorkflowTabConfig } from '@/components/Workflow/Show/Workflow.model.ts';

const WorkflowConsumption = () => {
  const { control } = useFormContext<WorkflowTabConfig>();

  return (
    <div>
      <FormField label="Enable food intake tracking" enableBorder={false}>
        <Controller
          defaultValue={false}
          name="workflowSetupForm.consumptionEnabled"
          control={control}
          render={({ value, onChange }: ControllerRenderProps) => (
            <Switch value={value} onChange={onChange} testId="enable-food-intake-switch" />
          )}
        />
      </FormField>
      <div
        className="pa3 bt b--moon-gray flex justify-between items-center"
        data-testid="workflow-consumption-after-save-select"
      >
        <label className="mb0 f6">After saving</label>
        <Controller
          defaultValue="focus-search"
          name={`workflowSetupForm.workflowTabConfig[4].onSave`}
          control={control}
          render={({ value, onChange }: ControllerRenderProps) => (
            <select className="mw5 mb0" value={value} onChange={onChange}>
              <option value="focus-search" data-testid="after-saving-option-Ofocus-search-bar">
                Focus search bar
              </option>
              <option value="first-subject" data-testid="after-saving-option-move-to-first-animal">
                Move to first animal
              </option>
              <option value="next-cage" data-testid="after-saving-option-move-to-next-cage">
                Move to next cage
              </option>
            </select>
          )}
        />
      </div>
    </div>
  );
};

export default WorkflowConsumption;
