import { FC, useEffect, useMemo, useRef, useState } from 'react';
import type { StudyApiId } from '@/model/Study.model.ts';
import Button from '@/components/UI/Button';
import { FormProvider, useForm, UseFormReturn } from 'react-hook-form@latest';
import { ApiService } from '@/support/ApiService.ts';
import { CageApiId } from '@/model/Cage.model.ts';
import { _isNotEmpty, _isNil, _isEmpty, _notNil } from '@/littledash.ts';
import { notAborted } from '@/support/Hooks/fetch/useAbortController.ts';
import ApiErrorBanner from '@/components/ApiErrorBanner';
import { DateUtils } from '@/utils/Date.utils.ts';
import ConsumptionMeasurements from '@/components/Workflow/Show/Views/Consumption/ConsumptionMeasurements.tsx';
import { ConsumptionMetric, ConsumptionMetricCreate, ConsumptionMetricStatus } from '@/model/Consumption.model.ts';
import { ErrorMessage } from '@hookform/error-message';
import Select from 'react-select';
import InVivoError from '@/model/InVivoError.ts';
import { WorkflowNoCageDataCard } from '@/components/Workflow/Show/Views/Consumption/WorkflowNoCageData.tsx';
import { errorToast } from '@/helpers.tsx';

interface FoodIntakeViewProps {
  studyApiId: StudyApiId;
  selectedCageApiId: CageApiId;
  cageConsumptionData?: Array<ConsumptionMetric>;
  cageMetricStatus?: ConsumptionMetricStatus;
  handleSave: () => void;
}

interface FoodIntakeFormData {
  fields: Record<string, number | string | null>;
}

type FoodIntakeFields = {
  consumption_out?: number;
  consumption_in?: number;
  spillage?: number;
  type: 'food';
  comment?: string;
};

type CreateConsumptionRequest = {
  studyId: StudyApiId;
  cageId: CageApiId;
  variables: ConsumptionMetricCreate;
};

type FieldConfigType = {
  id: string;
  label: string;
  disabled: boolean;
  required: boolean;
  errorMessage: string;
};

const CageDropdown = (
  <Select
    className="ui__select w5"
    classNamePrefix="ui__select"
    value={{
      value: 'food_intake',
      label: 'Cage Food Intake',
    }}
    options={[
      {
        value: 'food_intake',
        label: 'Cage Food Intake',
      },
    ]}
  />
);

const FoodIntakeView: FC<FoodIntakeViewProps> = ({
  studyApiId,
  selectedCageApiId,
  cageConsumptionData,
  cageMetricStatus,
  handleSave,
}) => {
  const formRef = useRef<HTMLFormElement | null>(null);
  const [commentInputDisabled, setCommentInputDisabled] = useState<boolean>(true);
  const [apiError, setApiError] = useState<unknown | false>(false);

  const todayRecord: ConsumptionMetric | undefined = useMemo(() => {
    if (_notNil(cageConsumptionData)) {
      return cageConsumptionData.find((record) => record.created_date === DateUtils.formatDate(new Date()));
    } else return undefined;
  }, [cageConsumptionData]);

  const lastFoodIn: number | null = useMemo(() => {
    return cageMetricStatus === 'end' ? null : (cageConsumptionData?.[0]?.consumption_in ?? null);
  }, [cageMetricStatus, cageConsumptionData]);

  const defaultValues: Record<string, number | string | null> = useMemo(() => {
    return {
      food_out: todayRecord?.consumption_out ?? null,
      food_in: todayRecord?.consumption_in ?? null,
      spillage: todayRecord?.spillage ?? null,
      comment: todayRecord?.comment ?? null,
    };
  }, [todayRecord]);

  const formMethods: UseFormReturn<FoodIntakeFormData> = useForm<FoodIntakeFormData>({
    defaultValues: {
      fields: defaultValues,
    },
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
  });
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = formMethods;

  useEffect(() => {
    reset({ fields: defaultValues });
    setCommentInputDisabled(true);
  }, [defaultValues]);

  const { foodInDisabled, foodOutDisabled } = useMemo(() => {
    let foodInDisabled = false;
    let foodOutDisabled = true;

    const dataIsEmpty = _isEmpty(cageConsumptionData) || _isNil(cageConsumptionData);
    if (!dataIsEmpty) {
      if (_isNotEmpty(todayRecord)) {
        foodInDisabled = _notNil(todayRecord.consumption_in) || _notNil(todayRecord.consumed);
        foodOutDisabled = _notNil(todayRecord.consumption_out);
      } else {
        foodOutDisabled = cageMetricStatus === 'end';
      }
    }

    return { foodInDisabled, foodOutDisabled };
  }, [cageConsumptionData, cageMetricStatus, todayRecord]);

  const onSubmit = async ({ fields }: { fields: Record<string, number | string | null> }) => {
    const variables: FoodIntakeFields = {
      type: 'food',
    };

    if (_notNil(fields.food_out) && !foodOutDisabled) {
      variables.consumption_out = fields.food_out as number;
    }
    if (_notNil(fields.food_in) && !foodInDisabled) {
      variables.consumption_in = fields.food_in as number;
    }
    if (_notNil(fields.spillage) && !foodOutDisabled) {
      variables.spillage = fields.spillage as number;
    }
    if (_notNil(fields.comment) && !commentInputDisabled) {
      variables.comment = fields.comment as string;
    }

    if (_notNil(lastFoodIn)) {
      const remainingFood: number = Number(variables.consumption_out ?? 0) + Number(variables.spillage ?? 0);
      if (remainingFood > lastFoodIn) {
        errorToast('Food remaining plus spillage cannot exceed last food in recorded');
        return;
      }
    }

    try {
      await createConsumption({
        studyId: studyApiId,
        cageId: selectedCageApiId,
        variables,
      });
    } catch (apiError) {
      if (notAborted(apiError)) {
        setApiError(apiError);
      }
    }
    handleSave();
  };

  const createConsumption = async (request: CreateConsumptionRequest): Promise<ConsumptionMetric> => {
    const createResponse = await ApiService.call({
      endpoint: 'POST /api/v1/studies/{studyApiId}/cages/{cageApiId}/consumption',
      path: { studyApiId: request.studyId, cageApiId: request.cageId },
      body: request.variables,
      options: { onError: { throw: false, capture: true, toast: false, slug: 'food-consumption-create' } },
    });
    if (createResponse.type === 'success') {
      return createResponse.body as ConsumptionMetric;
    }
    throw new InVivoError('Could not create consumption', {
      cause: createResponse.error,
      slug: 'food-consumption-create',
    });
  };

  const validateNumber = (fieldId: string, value: any) => {
    const notNumber = isNaN(value);
    if (notNumber) {
      return 'Must be a valid number';
    }
    const lessThanZero = value < 0;
    if (lessThanZero) {
      return 'Must be greater than 0';
    }
    if (fieldId !== 'food_in' && _notNil(lastFoodIn)) {
      if (value > lastFoodIn) {
        return 'Value exceeds last food in';
      }
    }
    return true;
  };

  const fieldConfig: Array<FieldConfigType> = [
    {
      id: 'food_out',
      label: 'Food Out (g)',
      disabled: foodOutDisabled,
      required: cageMetricStatus == 'initial' || cageMetricStatus == 'continuation',
      errorMessage: 'This field is required',
    },
    {
      id: 'food_in',
      label: 'Food In (g)',
      disabled: foodInDisabled,
      required: cageMetricStatus == 'end',
      errorMessage: 'This field is required',
    },
    {
      id: 'spillage',
      label: 'Spillage (g)',
      disabled: foodOutDisabled,
      required: false,
      errorMessage: '',
    },
  ];

  const fieldFormInput = (field: any) => (
    <div className="pb3" key={field.id}>
      <label>{field.label}</label>
      <input
        type="number"
        step="any"
        disabled={field.disabled}
        style={{ marginBottom: 0 }}
        data-testid={`input-${field.id}`}
        {...register(`fields.${field.id}`, {
          required: field.required ? field.errorMessage : false,
          pattern: {
            value: /^[-+]?[0-9]*\.?[0-9]+([eE][-+]?[0-9]+)?$/,
            message: 'Must be a valid number',
          },
          validate: (value) => validateNumber(field.id, value),
        })}
      />
      <ErrorMessage
        errors={errors}
        name={`fields.${field.id}`}
        render={({ message }) => <p className="f6 red db lh-copy pt1">{message}</p>}
      />
    </div>
  );

  return (
    <div className="flex flex-wrap justify-between items-stetch h-100">
      <div className="w-50 pv2 ph3 br b--moon-gray bg-white">
        <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
          {apiError ? (
            <ApiErrorBanner
              className="mw6 mb4"
              error={apiError}
              title="There was an error with your submission"
              text="Please correct the form errors below and try again. If this error persists try again later or contact support."
            />
          ) : null}
          <FormProvider {...formMethods}>
            <div className="bb b--moon-gray pv3">
              <div className="flex justify-between">
                {fieldConfig.slice(0, 2).map((field) => fieldFormInput(field))}
              </div>
              <div className="flex justify-between">{fieldFormInput(fieldConfig[2])}</div>
              <div className="pb3">
                <Button
                  className="f6 basier-reg pl0 stateless blue"
                  testId="workflow-foodintake-comment"
                  onClick={() => setCommentInputDisabled(!commentInputDisabled)}
                >
                  Add comment
                </Button>
                {!commentInputDisabled && (
                  <div className="mt2">
                    <input
                      type="text"
                      step="any"
                      disabled={commentInputDisabled || (foodOutDisabled && foodInDisabled)}
                      style={{ marginBottom: 0 }}
                      {...register('fields.comment', {
                        required: false,
                      })}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="pt3">
              <Button submit disabled={false} testId="workflow-foodintake-save">
                Save
              </Button>
            </div>
          </FormProvider>
        </form>
      </div>
      <div className="w-50 bg-white" data-testid="workflow-foodintake-sidebar">
        {_isNotEmpty(cageConsumptionData) ? (
          <>
            <div className="flex flex-wrap justify-between items-center pa3">{CageDropdown}</div>
            <ConsumptionMeasurements cageConsumptionData={cageConsumptionData} />
          </>
        ) : (
          <div className="mt4">{WorkflowNoCageDataCard}</div>
        )}
      </div>
    </div>
  );
};

export default FoodIntakeView;
